import { useState, useEffect} from "react"
import { client } from '../lib/apollo';
import { GET_FOOTER_OPTIONS } from '../queries/queries';

export default function Footer() {
  const [footerOptions, setFooterOptions] = useState(0);
  const [menuItems, setMenuItems] = useState(0);

  useEffect(()=>{
        async function fetchFooterOptions() {
            const response = await client.query({
                query: GET_FOOTER_OPTIONS
            });

            const footerOptions = response?.data?.siteOptions?.siteOptions; 
            setFooterOptions(footerOptions);

            const menuItems = response?.data?.menu?.menuItems?.edges;

            const groupMenuChildren = (
                data = []
            ) => {
                var output = [];
                console.log(data.length);

                if(data?.length){
                    let chunks = [];
                    const chunkSize = Math.ceil(data.length/2);
                    console.log(chunkSize);

                    for (let i = 0; i < data.length; i +=  chunkSize){
                        const chunk = data.slice(i, i + chunkSize);
                        chunks.push(chunk);
                    }

                    output = chunks;
                }
            
                return output;
            }

            setMenuItems(groupMenuChildren(menuItems));
        }

        fetchFooterOptions();
  }, []);

  return (
    <footer>
        <div id="top-footer-container" className="container-fluid">
            <div className="container">
                <div>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: footerOptions.contactInformation}}>
                        </div>
                        <div id="top-footer-social-container">
                            {
                                footerOptions.facebookLink && (
                                    <a aria-label="Facebook Link (opens in a new tab)" href={`${footerOptions.facebookLink}`} target="_blank">
                                        <span className="icon-facebook"></span>
                                    </a>
                                )
                            }
                            {
                                footerOptions.linkedinLink && (
                                    <a aria-label="LinkedIn Link (opens in a new tab)" href={`${footerOptions.linkedinLink}`} target="_blank">
                                        <span className="icon-linkedin"></span>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            {
                                menuItems && menuItems.length >0 && menuItems.map((item, index) => (
                                   <div>
                                        <ul>
                                            {
                                                item && item.map((item, index) => (
                                                    <li key={index}>{
                                                        <a href={`${item.node.path}`}>
                                                            <span className="icon-arrow"></span>
                                                            {item.node.label}
                                                        </a>
                                                    }</li>
                                                ))
                                            }
                                        </ul>
                                   </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>      
        </div>  
        <div id="bottom-footer-container" className="container-fluid">
            <div className="container">
                <div>
                    <div>
                        <div>
                            <p dangerouslySetInnerHTML={{__html: footerOptions.copyrightContent}}></p>
                            <a aria-label="Handcrafted by (opens link in a new tab)" href="https://cacpro.com" target="_blank">
                                Handcrafted by<span className="icon-cacpro"></span>
                            </a>
                        </div>
                        <p className="disclaimer" dangerouslySetInnerHTML={{__html: footerOptions.disclaimerText}}></p>
                    </div>
                    <div>
                        <div>
                            <div>
                                <a aria-label="(opens link in a new tab)" href="https://www.guidestar.org/profile/shared/2abc1114-5801-47b7-9268-d81251183373" target="_blank"><img src="https://widgets.guidestar.org/TransparencySeal/7663207" /></a>
                            </div>
                            {
                                footerOptions.logos && footerOptions.logos.length>0 && footerOptions.logos.map((item, index) => (
                                    <div key={index}>{
                                        item.link ? (
                                            <a aria-label="Logo Link (opens in a new tab)" href={`${item.link}`} target="_blank">
                                                <img src={`${item.logo.sourceUrl}`} />
                                            </a>
                                        ) : (
                                            <img src={`${item.logo.sourceUrl}`} />
                                        )
                                    }</div>
                                ))
                            }
                        </div>
                        <p className="mobile-disclaimer" dangerouslySetInnerHTML={{__html: footerOptions.disclaimerText}}></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}