import { useState, useEffect} from "react"
import { client } from '../lib/apollo';
import { GET_HEADER_OPTIONS } from '../queries/queries';

export default function Header() {
  const [topHeaderMenu, setTopHeaderMenu] = useState('');
  const [mainMenu, setMainMenu] = useState('');
  const [mobileMenuClass, setMobileMenuClass] = useState('');
  const [iconClass, setIconClass] = useState('');
  const [searchClass, setSearchClass] = useState('');
  const [subMenuClass, setSubMenuClass] = useState('');

  const toggleMobileMenu = (e) => {
    e.preventDefault();
    
    if(mobileMenuClass === ''){
        setMobileMenuClass('is-visible');
        setIconClass('active');
    } else {
        setMobileMenuClass('');
        setIconClass('');
    }
  };

  const toggleSearch = (e) => {
    e.preventDefault();
    
    if(searchClass === ''){
        setSearchClass('is-visible');
    } else {
        setSearchClass('');
    }
  };

  const toggleMobileMenuSubMenu = (e, index) => {
    e.preventDefault();
    
    if(subMenuClass === index){
        setSubMenuClass('');
    } else {
        setSubMenuClass(index);
    }
  };

  useEffect(()=>{
        async function fetchHeaderOptions() {
            const response = await client.query({
                query: GET_HEADER_OPTIONS
            });

            const topHeaderMenu = response?.data?.topMenu?.menuItems?.edges;
            const mainMenu = response?.data?.mainMenu?.menuItems?.nodes;
            const flatListToHierarchical = (
                data = [],
                {idKey='key',parentKey='parentId',childrenKey='children'} = {}
            ) => {
                const tree = [];
                const childrenOf = {};
                data.forEach((item) => {
                    const newItem = {...item};
                    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
                    childrenOf[id] = childrenOf[id] || [];
                    newItem[childrenKey] = childrenOf[id];
                    parentId
                        ? (
                            childrenOf[parentId] = childrenOf[parentId] || []
                        ).push(newItem)
                        : tree.push(newItem);
                });
                return tree;
            };

            const groupMenuChildren = (
                data = []
            ) => {
                data.forEach( (item) => {
                    if(item?.children?.length){
                        let chunks = [];
                        const chunkSize = Math.ceil(item.children.length/2);

                        for (let i = 0; i < item.children.length; i +=  chunkSize){
                            const chunk = item.children.slice(i, i + chunkSize);
                            chunks.push(chunk);
                        }

                        item.children = chunks;
                    }
                })
            
                return data;
            }
            
            const mm = flatListToHierarchical(mainMenu);
            const mmFinal = groupMenuChildren(mm);

            setTopHeaderMenu(topHeaderMenu);
            setMainMenu(mmFinal);
        }

        fetchHeaderOptions();
  }, []);

  return (
    <>
    <div id="header-container" className="container xlarge-container">
        <div>
            <div>
                <a href="/">
                    <img src="/assets/images/logo.svg" alt="The Signatry" />
                </a>
            </div>
            <div>
                <div>
                    <ul>
                        {
                            topHeaderMenu && topHeaderMenu.length >0 && topHeaderMenu.map((item, index) => (
                                <li key={index}>{
                                    <>
                                        <a href={`${item.node.path}`}>
                                            {item.node.label}
                                        </a>
                                        <span>|</span>
                                    </>
                                }</li>
                            ))
                        }
                    </ul>
                    <a id="desktop-search-toggle" aria-label='Search' href="/search/">
                        <span className="icon-search"></span>
                    </a>
                </div>
                <div>
                    {
                         mainMenu && mainMenu.length > 0 && (
                            <ul>
                                {
                                    mainMenu.map((item, index) => {
                                        return (
                                            <li key={index} className={`${item.children.length > 0 ? 'menu-item menu-item-has-children' : 'menu-item'}`}>
                                                {
                                                    item.path && item.title !== 'Blog' && (
                                                        <a href={`${item.path}`}>
                                                            {item.title}
                                                        </a>
                                                    )
                                                }
                                                {
                                                    item.path && item.title == 'Blog' && (
                                                        <a aria-label="The Signatry Blog" href={`${item.path}`}>
                                                            {item.title}
                                                        </a>
                                                    )
                                                }
                                                {
                                                    !item.path && (
                                                        <span>{item.title}</span>
                                                    )
                                                }
                                                {
                                                    item.children.length > 0 && (
                                                        <div className="mega-menu">
                                                            <div>
                                                                <div>
                                                                    {
                                                                        item.menuItem.largeDescription && (
                                                                            <div dangerouslySetInnerHTML={{__html: item.menuItem.largeDescription}}></div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    {
                                                                        item.children.map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    <ul>
                                                                                        {
                                                                                            item.map((item, index) => {
                                                                                                return (
                                                                                                    <li key={index}>
                                                                                                        <a href={`${item.path}`}>
                                                                                                            <span className="icon-arrow"></span>
                                                                                                            {item.title}
                                                                                                        </a>
                                                                                                        {
                                                                                                            item.menuItem.description && (
                                                                                                                <p>{item.menuItem.description}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    </li>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        )
                    }
                </div>
            </div>
        </div>
        <a aria-label="Mobile Search Link" id="mobile-search-toggle" href="/search/">
            <span className="icon-search"></span>
        </a>
        <a aria-label="Mobile Menu Link" id="mobile-menu-icon" href="#" className={`${iconClass}`}
            onClick={(e) => {
                toggleMobileMenu(e);
              }}
        >
            <span className="icon-menu"></span>
            <span className="icon-close"></span>
        </a>
    </div>
    <div id="mobile-menu" className={`${mobileMenuClass}`}>
        <div id="mobile-menu-main">
            <ul>
                {
                    mainMenu && mainMenu.length >0 && mainMenu.map((item, index) => {
                        return (
                            <li key={index} className={`${item.children.length > 0 ? 'menu-item menu-item-has-children' : 'menu-item'}`}>
                               {
                                    item.children?.length > 0 && (
                                        <a href="#" className={`${subMenuClass === index ? 'active' : '' }`} onClick={(e) => {
                                            toggleMobileMenuSubMenu(e, index);
                                          }}>
                                            {item.title}
                                        </a>
                                    )
                               }
                               {
                                    item.children.length === 0 && (
                                        <a href={`${item.path}`}>
                                            {item.title}
                                        </a>
                                    )
                               }
                                {
                                    item.children.length > 0 && (
                                        <div className={`sub-menu ${subMenuClass === index ? 'active' : '' }`}>
                                            <ul>
                                                {
                                                    item.children.map((item) => {
                                                        return (
                                                            item.map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <a href={`${item.path}`}>
                                                                            <span className="icon-arrow"></span>
                                                                            {item.title}
                                                                        </a>
                                                                        {
                                                                            item.menuItem.description && (
                                                                                <p>{item.menuItem.description}</p>
                                                                            )
                                                                        }
                                                                    </li>
                                                                )
                                                            })
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    )
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </div>
        <div id="mobile-menu-secondary">
            <ul>
                {
                    topHeaderMenu && topHeaderMenu.length >0 && topHeaderMenu.map((item, index) => (
                        <li key={index}>{
                            <>
                                <a href={`${item.node.path}`}>
                                    {item.node.label}
                                </a>
                            </>
                        }</li>
                    ))
                }
            </ul>
        </div>
    </div>
    </>
  );
}